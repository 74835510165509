sl-button[size="large"]::part(base) {
  white-space: normal;
  line-height: inherit;
  padding-block: var(--sl-spacing-small);
  box-shadow: 10px 22px 20px -8px var(--heading-shadow);
}

sl-dialog {
  --body-spacing: 0.75rem 1.25rem;
}

sl-dialog::part(title) {
  --header-spacing: 0.75rem 0 0.75rem 1.25rem;
  --sl-font-size-large: 1.75rem;
  font-family: var(--heading-font);
  font-weight: 724;
  font-variation-settings: "YTLC" 510, "opsz" 8.9;
  color: var(--primary-dark-color);
}

@media (max-width: 450px) {
  sl-dialog::part(close-button) {
    --header-spacing: 0.5rem;
  }

  sl-dialog::part(panel) {
    max-width: 96%;
  }
}

sl-input[size="medium"], sl-textarea[size="medium"] {
  --sl-input-font-size-medium: var(--sl-font-size-small);

  @media (--wf-breakpoint-xs) {
    /* ensure no weird zoom on mobile focus */
    --sl-input-font-size-medium: 16px;
  }
}

p a sl-icon {
  margin-right: var(--sl-spacing-2x-small);
  margin-left: var(--sl-spacing-3x-small);
  vertical-align: -0.125em;
}

sl-dialog p {
  margin: 0 0 var(--sl-font-size-medium);
}

sl-option {
  &::part(base) {
    gap: .5em;
  }

  &::part(checked-icon) {
    padding-inline-end: 0;
    margin-inline-end: -.85em;
  }
}
